<template>
  <log-table
    @refresh="getLogs"
    :environment="envName"
    :headers="headers"
    :logs="logs"
    :name="correlationUid"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'correlationLogs',
  components: {
    logTable: () => import('@/components/logTable.vue'),
  },
  props: {
    envName: {
      type: String,
      default: '',
      required: true,
    },
    correlationUid: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Date',
        value: 'datetime',
      },
      {
        text: 'Severity',
        value: 'severity',
      },
      {
        text: 'Application',
        value: 'application',
      },
      {
        text: 'Message',
        value: 'message',
      },
    ],
    logs: [],
  }),
  created() {
    this.getLogs();
  },
  methods: {
    ...mapActions({
      fetchGameOrCoreLogs: 'fetch/fetchGameOrCoreLogs',
    }),
    getLogs() {
      this.fetchGameOrCoreLogs({
        url: `/v2/log/correlation/${this.envName}`,
        params: {
          correlation_uid: this.correlationUid,
        },
      }).then((result) => { this.logs = result; });
    },
  },
};
</script>
